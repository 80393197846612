/* VideoPlayer.css */
.youtube-container {
  margin: 20px auto; /* Add margin for spacing */
}

.youtube-iframe {
  border: 2px solid #333; /* Add a border around the video */
  border-radius: 8px; /* Add border-radius for rounded corners */
  /* width: 100%; */
}

.video-details {
    background-color: black; /* Set the background color to black */
    padding: 20px; /* Add padding for spacing */
    color: white; /* Set text color to white */
  }

/* .exit-button {
  background-color: #333;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px; 
}

.exit-button:hover {
  background-color: #555;
} */

.exit-button {
  display: inline-block;
  width: 100%; 
  /* margin: 40px auto;  */
  margin: 10px 10px;
  padding: 10px 10px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 12px;
  /* border: 2px solid #fff;  */
  transition: transform 0.3s ease-in-out; 
  /* text-align: center; */
  cursor: pointer;
}

.exit-button:hover {
  /* transform: scale(1.1);  */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  /* background-color: #555; */
}


h2,
p {
  color: white;
  margin-bottom: 15px;
}

/* Additional styling for a professional look */
h2 {
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  /* .youtube-container {
    margin: 10px auto; 
  } */

  /* .youtube-iframe {
    height: 100%;
  } */

  /* .video-details {
    padding: 10px; 
  } */

  /* .exit-button {
    width: auto;
    margin: 10px 0; 
  } */
}