/* FilmSubmission.css */
.film-submission-container {
  text-align: center;
  margin-top: 20px;
}

video {
  width: 80%; /* Adjust the width as needed */
  max-width: 100%;
  margin-bottom: 10px;
  cursor: pointer; /* Add cursor pointer effect */
  border: 2px solid #fff; /* Add white border to the video frame */
  border-radius: 8px;
}

.join-button {
  display: inline-block;
  width: 80%; /* Change to block for centering */
  margin: 40px auto; /* Center the button */
  padding: 10px 10px;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
  border: 2px solid #fff; /* Add white border */
  transition: transform 0.3s ease-in-out; /* Add hover effect */
}

.join-button:hover {
  transform: scale(1.1); /* Scale up on hover */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

  