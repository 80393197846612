/* Add this CSS to your Navbar.css or your global styles */

.navbar {
    background-color: black;
    padding: 10px; /* Adjust padding as needed */
    display: flex;
    justify-content: center;
    /* margin: 20px 0; */
  }
  
  .navbar img {
    width: 100px; /* Adjust the width of the logo */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer;
    margin: 20px 0;
  }
  
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .navbar {
      padding: 5px; /* Adjust padding for mobile */
    }
  
    .navbar img {
      width: 80px; /* Adjust the width of the logo for mobile */
    }
  }
  