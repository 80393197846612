/* Footer.css */
.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding: 10px;
  }
  
  .footer p {
    margin: 0;
    margin: 20px 0;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .footer {
      font-size: 14px; /* Adjust font size for mobile */
    }
  }
  