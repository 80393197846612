/* HeroSection.css */

.hero-section {
    margin-top: 20px;
    overflow: hidden; /* Ensure the border doesn't create a horizontal scrollbar */
    /* Add right margin to reduce width on the right side */
    /* margin-right: 20px;  */
  }
  
  .slick-prev, .slick-next {
    font-size: 24px;
    color: white;
    z-index: 1;
    background: rgba(255, 255, 255, 0.5); /* White background with some transparency */
    border: 2px solid white; /* White border */
    border-radius: 50%; /* Circular shape */
    opacity: 0; /* Hide the arrow by default */
    transition: opacity 0.3s ease; /* Add a transition effect */
  }
  
  .slick-prev:hover, .slick-next:hover {
    background: rgba(255, 255, 255, 0.8); /* Increase transparency on hover */
  }
  
  .slick-prev:hover, .slick-next:hover, .slick-prev:hover:before, .slick-next:hover:before {
    opacity: 1; /* Show the arrow when hovered */
  }
  
  .slick-prev:before, .slick-next:before {
    content: ""; /* Remove the content (text) */
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    background: rgba(255, 255, 255, 0.8); /* Increase transparency on hover */
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  .movie-slide {
    position: relative;
    border: 2px solid white; /* White border for the container */
    border-radius: 8px;
    overflow: hidden; /* Ensure the border doesn't create a horizontal scrollbar */
    height: 350px; /* Set a specific height for the container */
    cursor: pointer;
  }

  .movie-slide img {
    /* height: auto; */
    border-radius: 8px; /* Add border radius if needed */
    
    width: 100%;
    height: 100%; /* Set the height to 100% to fill the container */
    object-fit: cover;
  }

  .hero-section:hover {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }

 
  .movie-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .movie-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .movie-info p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }
  
  
  
  
  

  
  