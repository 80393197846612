/* Add this to your styles.css or App.css */

body {
  /* margin: 0; */
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  /* max-width: 1200px;  */
  /* overflow: hidden; */
  /* background-color: #cb5757; */
  background-color: black;
}

.navbar {
  background-color: black;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid white; /* Add a border at the bottom */
}

.navbar img {
  width: 150px;
  cursor: pointer;
}

.thumbnail-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px; /* Add margin at the top to create space */
}

.thumbnail {
  width: 180px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.thumbnail img {
  width: 100%;
  border-radius: 8px;
}

.thumbnail p {
  margin: 5px 0;
  text-align: center;
}

/* Add hover effect */
.thumbnail:hover {
  transform: scale(1.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .thumbnail {
    width: 100%;
  }

  .navbar {
    padding: 10px 0; /* Adjust padding for smaller screens */
  }

  .footer {
    padding: 10px 0; /* Adjust padding for smaller screens */
  }
}

.footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  border-top: 2px solid white; /* Add a border at the top */
  /* position: fixed; */
  bottom: 0;
  width: 100%;
}

