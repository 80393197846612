/* ThumbnailList.css */
.thumbnail-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* justify-content: space-around; */
    /* justify-content: space-evenly; */
    
    /* background-color: #cb5757; */
    background-color: #111;
    /* background-color: black; */
    margin: 20px 0;
    /* margin-right: 25px; */
    
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
    gap: 20px;
    padding: 20px;
    /* background-color: #111; */
  }

  /* .thumbnail-list .thumbnail {
    width: 240px;
    height: 240px;
  } */
  
  .thumbnail {
    background-color: black;
    position: relative;
    width: calc(20% - 20px);
    /* height: 240px; */
    height: 210px;
    overflow: hidden;
    /* width: 180px; */
    margin: 10px;
    cursor: pointer;
    border-radius: 4%;
    transition: transform 0.3s ease-in-out;
    border: 2px solid #fff;
    /* transition: box-shadow 0.3s; */
    /* transition: transform 0.3s, filter 0.3s, box-shadow 0.3s; */
  }
  
  .thumbnail img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    /* object-fit: none; */
    border-radius: 8px;
    display: block;
    image-rendering: auto;
  }
  
  .thumbnail p {
    position: absolute;
    /* bottom: 5px; */
    left: 0;
    right: 0;
    margin: 5px 0;
    text-align: center;
    font-size: 14px;
    color: white;
  }
  
  /* Add hover effect */
  .thumbnail:hover {
    transform: scale(1.1);
    /* filter: brightness(1.2); */
    /* border-color: #ffffffa9; */
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    /* .thumbnail {
      width: 100%;
      height: auto;
    } */

    /* .thumbnail p {
      display: none;
    } */

    .thumbnail-list {
      margin-right: 25px;
    }
  }
  